import React, { lazy, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { Loading } from 'components/shared/Loading/Loading';
import { AdminOutlet } from 'routes/Admin/AdminOutlet';
import { RootOutlet } from 'routes/RootOutlet';
import { isCurrentUserAuthenticated } from 'store/selectors';

const ExecutionsRoute = lazy(() => import('routes/Executions/Executions.route'));
const PositionRoute = lazy(() => import('routes/Position/Position.route'));
const OrdersRoute = lazy(() => import('routes/Orders/Orders.route'));
const KillSwitchRoute = lazy(() => import('routes/Admin/KillSwitch.route'));
const MassCancellationRoute = lazy(() => import('routes/Admin/MassCancellation.route'));
const UnauthorizedRoute = lazy(() => import('routes/Unauthorized/Unauthorized.route'));

export const AppRouter: React.FC = () => {
  const isAuthenticated = useSelector(isCurrentUserAuthenticated);

  const router = useMemo(() => {
    const admin = {
      path: 'admin',
      element: <AdminOutlet />,
      children: [
        {
          index: true,
          element: <Navigate to="/admin/mass-cancellation" />,
        },
        {
          path: 'mass-cancellation',
          Component: MassCancellationRoute,
        },
        {
          path: 'kill-switch',
          Component: KillSwitchRoute,
        },
      ],
    };

    return createBrowserRouter([
      {
        path: '/',
        element: <RootOutlet />,
        children: [
          {
            index: true,
            element: <Navigate to="/orders" />,
          },
          {
            path: 'orders',
            Component: OrdersRoute,
          },
          {
            path: 'executions',
            Component: ExecutionsRoute,
          },
          {
            path: 'position',
            Component: PositionRoute,
          },
          {
            ...admin,
          },
          {
            path: 'unauthorized',
            Component: UnauthorizedRoute,
          },
        ],
      },
      {
        path: '*',
        element: <Navigate to="/orders" />,
      },
    ]);
  }, []);

  return isAuthenticated ? (
    <RouterProvider router={router} />
  ) : (
    <Loading type="blinker" e2e="authenticating-loading-blinker" />
  );
};
