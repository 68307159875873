import { combineEpics, Epic, ofType } from 'redux-observable';
import { map, catchError, of, mergeMap, withLatestFrom, EMPTY } from 'rxjs';
import { DcErrorResponse } from '../../models/Responses/DcErrorResponse';
import {
  filterCustomersDisabled,
  filterCustomersFetched,
  filterCustomersFetchFailed,
  filterCustomerSystemsDisabled,
  filterCustomerSystemsFetchFailed,
  filterProductsFetched,
  filterProductsFetchFailed,
  filterTradingSystemsDisabled,
  filterTradingSystemsFetchFailed,
  filterUserSystemsDisabled,
  filterUserSystemsFetchFailed,
} from './filters.actions';
import {
  isCustomerFilterOptionsFetched,
  isCustomerSystemFilterOptionsFetched,
  isTradingSystemFilterOptionsFetched,
  isUserSystemFilterOptionsFetched,
} from './filters.selectors';
import {
  customerSystemsFilterOptionsFetchedThunk,
  tradingSystemsFilterOptionsFetchedThunk,
  userSystemsFilterOptionsFetchedThunk,
} from './filters.thunks';
import { defaultCustomer, defaultCustomerSystemId, defaultTradingSystem } from './filtersHelper';
import { filtersApiConnector, FiltersApi } from 'api/filters';
import { DcEpic } from 'store/epics';
import {
  QUERY_CUSTOMER_SYSTEM_UPDATED,
  QUERY_CUSTOMER_UPDATED,
  QUERY_EXCHANGE_UPDATED,
  QUERY_TRADING_SYSTEM_UPDATED,
  QueryCustomerSystemUpdated,
  QueryCustomerUpdated,
  QueryExchangeUpdated,
  QueryTradingSystemUpdated,
} from 'store/queries/queries.actions';
import {
  getSelectedCustomer,
  getSelectedCustomerSystemId,
  getSelectedExchange,
  getSelectedTradingSystem,
  isProductFilterOptionsFetched,
} from 'store/selectors';
import { RootState } from 'store/state';
import { httpClient } from 'utils/http/httpClient';

const filtersApi$ = filtersApiConnector(httpClient);

export const filterProductsRequestedEpic =
  (api$: FiltersApi): Epic =>
  (actions$, state$) =>
    actions$.pipe(
      ofType(QUERY_EXCHANGE_UPDATED),
      withLatestFrom<QueryExchangeUpdated, [RootState]>(state$),
      mergeMap(([{ value: exchange }, state]) => {
        if (!exchange) {
          return EMPTY;
        }

        if (isProductFilterOptionsFetched(exchange)(state)) {
          return EMPTY;
        }

        return api$.getFilterOptions(exchange, 'Products').pipe(
          map(response => filterProductsFetched(exchange, response)),
          catchError((err: DcErrorResponse) => of(filterProductsFetchFailed(exchange, err))),
        );
      }),
    );

export const filterCustomersRequestedEpic =
  (api$: FiltersApi): Epic =>
  (actions$, state$) =>
    actions$.pipe(
      ofType(QUERY_EXCHANGE_UPDATED),
      withLatestFrom<QueryExchangeUpdated, [RootState]>(state$),
      mergeMap(([{ value: exchange }, state]) => {
        if (!exchange) {
          return EMPTY;
        }

        if (isCustomerFilterOptionsFetched(exchange)(state)) {
          return of(filterCustomersDisabled(false));
        }

        return api$.getFilterOptions(exchange, 'Customers').pipe(
          map(response => filterCustomersFetched(exchange, response)),
          catchError((err: DcErrorResponse) => of(filterCustomersFetchFailed(exchange, err))),
        );
      }),
    );

export const filterTradingSystemsRequestedEpic =
  (api$: FiltersApi): Epic =>
  (actions$, state$) =>
    actions$.pipe(
      ofType(QUERY_EXCHANGE_UPDATED, QUERY_CUSTOMER_UPDATED),
      withLatestFrom<QueryExchangeUpdated | QueryCustomerUpdated, [RootState]>(state$),
      mergeMap(([{ queryType }, state]) => {
        const exchange = getSelectedExchange(queryType)(state);
        const customer = getSelectedCustomer(queryType)(state);

        if (!exchange) {
          return EMPTY;
        }

        if (isTradingSystemFilterOptionsFetched(exchange, customer ?? defaultCustomer)(state)) {
          return of(filterTradingSystemsDisabled(false));
        }

        return api$.getFilterOptions(exchange, 'Trading-systems', { customer }).pipe(
          map(response =>
            tradingSystemsFilterOptionsFetchedThunk(exchange, customer ?? defaultCustomer, response, queryType),
          ),
          catchError((err: DcErrorResponse) =>
            of(filterTradingSystemsFetchFailed(exchange, customer ?? defaultCustomer, err)),
          ),
        );
      }),
    );

export const filterCustomerSystemsRequestedEpic =
  (api$: FiltersApi): Epic =>
  (actions$, state$) =>
    actions$.pipe(
      ofType(QUERY_EXCHANGE_UPDATED, QUERY_CUSTOMER_UPDATED, QUERY_TRADING_SYSTEM_UPDATED),
      withLatestFrom<QueryExchangeUpdated | QueryCustomerUpdated | QueryTradingSystemUpdated, [RootState]>(state$),
      mergeMap(([{ queryType }, state]) => {
        const exchange = getSelectedExchange(queryType)(state);
        const customer = getSelectedCustomer(queryType)(state);
        const tradingSystem = getSelectedTradingSystem(queryType)(state);

        if (!exchange) {
          return EMPTY;
        }

        if (
          isCustomerSystemFilterOptionsFetched(
            exchange,
            customer ?? defaultCustomer,
            tradingSystem ?? defaultTradingSystem,
          )(state)
        ) {
          return of(filterCustomerSystemsDisabled(false));
        }

        return api$.getFilterOptions(exchange, 'Customer-systems', { customer, tradingSystem }).pipe(
          map(response =>
            customerSystemsFilterOptionsFetchedThunk(
              exchange,
              customer ?? defaultCustomer,
              tradingSystem ?? defaultTradingSystem,
              response,
              queryType,
            ),
          ),
          catchError((err: DcErrorResponse) =>
            of(
              filterCustomerSystemsFetchFailed(
                exchange,
                customer ?? defaultCustomer,
                tradingSystem ?? defaultTradingSystem,
                err,
              ),
            ),
          ),
        );
      }),
    );

export const filterUserSystemsRequestedEpic =
  (api$: FiltersApi): Epic =>
  (actions$, state$) =>
    actions$.pipe(
      ofType(
        QUERY_EXCHANGE_UPDATED,
        QUERY_CUSTOMER_UPDATED,
        QUERY_TRADING_SYSTEM_UPDATED,
        QUERY_CUSTOMER_SYSTEM_UPDATED,
      ),
      withLatestFrom<
        QueryExchangeUpdated | QueryCustomerUpdated | QueryTradingSystemUpdated | QueryCustomerSystemUpdated,
        [RootState]
      >(state$),
      mergeMap(([{ queryType }, state]) => {
        const exchange = getSelectedExchange(queryType)(state);
        const customer = getSelectedCustomer(queryType)(state);
        const tradingSystem = getSelectedTradingSystem(queryType)(state);
        const customerSystemId = getSelectedCustomerSystemId(queryType)(state);

        if (!exchange) {
          return EMPTY;
        }

        if (
          isUserSystemFilterOptionsFetched(
            exchange,
            customer ?? defaultCustomer,
            tradingSystem ?? defaultTradingSystem,
            customerSystemId ?? defaultCustomerSystemId,
          )(state)
        ) {
          return of(filterUserSystemsDisabled(false));
        }

        return api$
          .getFilterOptions(exchange, 'User-systems', { customer, tradingSystem, customerId: customerSystemId })
          .pipe(
            map(response =>
              userSystemsFilterOptionsFetchedThunk(
                exchange,
                customer ?? defaultCustomer,
                tradingSystem ?? defaultTradingSystem,
                customerSystemId ?? defaultCustomerSystemId,
                response,
                queryType,
              ),
            ),
            catchError((err: DcErrorResponse) =>
              of(
                filterUserSystemsFetchFailed(
                  exchange,
                  customer ?? defaultCustomer,
                  tradingSystem ?? defaultTradingSystem,
                  customerSystemId ?? defaultCustomerSystemId,
                  err,
                ),
              ),
            ),
          );
      }),
    );

export const filtersEpics = (): DcEpic =>
  combineEpics(
    filterProductsRequestedEpic(filtersApi$),
    filterCustomersRequestedEpic(filtersApi$),
    filterTradingSystemsRequestedEpic(filtersApi$),
    filterCustomerSystemsRequestedEpic(filtersApi$),
    filterUserSystemsRequestedEpic(filtersApi$),
  );
