import { Thunk } from '../actions';
import {
  cancelOrdersBatchInitRequested,
  cancelOrdersBatchUpdated,
  cancelOrdersStopRequested,
} from './cancellations.actions';
import { CancellationBatchResponse } from 'models/Cancellations/CancellationBatchResponse';

export const cancelSelectedOrdersRequestedThunk =
  (exchange: string, selectedOrderIds: string[]): Thunk<void> =>
  (dispatch, _) => {
    dispatch(cancelOrdersBatchInitRequested(exchange, selectedOrderIds));
  };

export const cancelOrdersBatchUpdatedThunk =
  (exchange: string, cancelResponse: CancellationBatchResponse): Thunk<void> =>
  (dispatch, _) => {
    dispatch(cancelOrdersBatchUpdated(exchange, cancelResponse));

    const cancellationStatus = cancelResponse.status;
    if (cancellationStatus === 'DONE') {
      dispatch(cancelOrdersStopRequested());
    }
  };
